import $ from 'jquery';

$( document ).ready(function() {
    onloadInitRecaptcha();
});

/* START functions for invisible recaptcha */
var onloadInitRecaptcha = function () {
    if (typeof grecaptcha === 'undefined' || grecaptcha === null) {
        setTimeout(function () {
            onloadInitRecaptchaLoop()
        }, 2000)
    } else {
        setTimeout(function () {
            onloadInitRecaptchaLoop()
        }, 1000)
    }
}

var onloadInitRecaptchaLoop = function () {
    var i = 1
    var newCaptchaId = 'recaptchaid-'
    $('.g-recaptcha').each(function () {
        if (!$(this).attr('id')) {
            $(this).attr('id', newCaptchaId + i)
        }
        var currentElement = $(this)
        initRecaptcha(currentElement)
        i++
    })
}

var initRecaptcha = function (recaptchaDiv) {
    var recaptchaid = grecaptcha.render(recaptchaDiv.attr('id'), {
        'sitekey': siteKeyRecaptcha,
        'callback': function (token) {
            onSubmitFormWithRecaptcha(token, recaptchaDiv)
        }
    })
    recaptchaDiv.attr('data-recaptchaid', recaptchaid)
}

var onSubmitFormWithRecaptcha = function (token, currentElement) {
    return new Promise(function (resolve, reject) {
        if (grecaptcha === undefined) {
            //return;
            reject()
        }
        var response = grecaptcha.getResponse(currentElement.attr('data-recaptchaid'));
        if (!response) {
            //return;
            reject()
        }

        var tmpForm = currentElement.parents('form').eq(0)
        tmpForm.submit()
    }) //end promise
}
/* END functions for invisible recaptcha */
