import $ from "jquery";

const lang = window.pimcore_locale;
const ajaxOptionUrl = `/boeckmann/api/${lang}/product-finder/field-options`

export default function reFetchProduktFinderSelectizeOptions(selectEl, formData) {
    let select = $(selectEl);
    let selectize = selectEl.selectize;

    var name = select.attr('name');
    var rootName = 'finder';
    var names = name
        .replace(rootName, '')
        // remove all [ chars
        .replace(/\[/g, '')
        // split string on ]
        .split(']')
        // filter empty values
        .filter((el) => {return el !== ''});

    names.unshift(name.split('[')[0]);

    selectize.on('focus', function () {
        const request = $.ajax({
            url: ajaxOptionUrl,
            method: "GET",
            data: Object.assign({
                'form_select_name_path': names
            }, formData),
            dataType: "html"
        });

        request.done(function( options ) {
            if (!selectize.$control.parent().hasClass('multi')) {
                selectize.clear(true);
            }

            selectize.clearOptions(true);
            JSON.parse(options).map(function (option) {
                selectize.addOption(option);
            });
            selectize.refreshOptions();
        });

        request.fail(function( jqXHR, textStatus ) {
            console.error( "Request failed: " + textStatus );
        });
    });
}