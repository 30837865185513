import $ from "jquery";


// 2. This code loads the IFrame Player API code asynchronously.
var tag = document.createElement('script');

tag.src = "https://www.youtube.com/iframe_api";
$('body').append(tag);

window.onYouTubeIframeAPIReady = function() {

    var videoBackground = null;

    var handleYoutube = function(videoTeaser, overlaySelector, resource) {
            var playerDestinationDiv = videoTeaser.find('.video-player')[0];
            var player = new YT.Player(playerDestinationDiv, {
                origin: location.protocol +'//'+ location.host,
                videoId: resource,
                events: {
                    'onReady': function (youtubeEvent) {
                        videoTeaser.find(overlaySelector).on('click', function (clickEvent) {
                            console.log('click');
                            youtubeEvent.target.playVideo();
                            videoTeaser.addClass('video-is-playing');
                        });
                    },
                }
            });
    };

    var handleAsset = function(videoTeaser, overlaySelector, resource) {
        var video = videoTeaser.find('video')[0];

        videoTeaser.find(overlaySelector).on('click', function (clickEvent) {
            video.play();
            videoTeaser.addClass('video-is-playing');
        });
    };

    var handleDefault = function(videoTeaser, overlaySelector, resource) {
        videoTeaser.find(overlaySelector).on('click', function (clickEvent) {
            videoTeaser.addClass('video-is-playing');
        });
    };

    var handleYoutubePermission = function (videoTeaser, overlaySelector, resource) {
        if (CCM && typeof (CCM.acceptedEmbeddings.find(element => element.name == 'YouTube')) !== 'undefined') {
            videoTeaser.removeClass('no-consent');
            handleYoutube(videoTeaser, overlaySelector, resource);
        } else {
            videoTeaser.addClass('no-consent');
        }
        window.addEventListener('ccm19WidgetClosed', function (e) {
            handleYoutubePermission(videoTeaser, overlaySelector, resource);
        });
    }

    $('.video-teaser').each(function () {
        var type = $(this).data('video-type');
        var resource = $(this).data('video-resource');
        var overlaySelector = '.video-image-wrapper';

        switch (type) {
            case 'youtube':
                handleYoutubePermission($(this), overlaySelector, resource);
                break;
            case 'asset':
                handleAsset($(this), overlaySelector, resource);
                break;
            default:
                handleDefault($(this), overlaySelector, resource);
        }
    });
};
