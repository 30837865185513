import $ from 'jquery';

$(document).ready(function () {

    $(document).on('click', '.merchant-search-item-show-product-button', function() {
        var show = $(this).closest('.merchant-search-item').find('.hide-container').removeClass('hide-for-small-only');
        $(this).closest('.merchant-search-item').find('.merchant-search-item-hide-product-button').removeClass('hide');
        $(this).addClass('hide');
        return false;
    });

    $(document).on('click', '.merchant-search-item-hide-product-button', function() {
        var show = $(this).closest('.merchant-search-item').find('.hide-container').addClass('hide-for-small-only');
        $(this).closest('.merchant-search-item').find('.merchant-search-item-show-product-button').removeClass('hide');
        $(this).addClass('hide');
        return false;
    });

    $('.list-with-title').each(function() {
        if($(this).find('ul > li').length == 0) {
            $(this).hide().addClass('hide');
        }
    });

    $('.merchant-search-item').each(function() {
        if($(this).find('.hide-container').html().trim() == '') {
            $(this).find('.merchant-search-item-show-product-button').hide();
        } else {
            if($(this).find('.list-with-title:not(.merchant-search-item-offer-list)').length == $(this).find('.list-with-title.hide').length) {
                $(this).find('.merchant-search-item-show-product-button').hide();
            }
        };
    });


});

