import $ from "jquery";

import 'selectize';

$( document ).ready(function() {
    const vinForm = document.getElementById('vin-search-form');
    const vin= document.querySelector('.main.shop .search-fin');
    let vinValueInitial = '';
    const parts= document.querySelector('.main.shop .search-parts');

    // value as placeholder
    if (vin) {
        vinValueInitial = vin.value;
        vin.onfocus=function(e){
            if (this.value === vinValueInitial) {
                this.value='WB0';
                this.classList.add('upper');
            }
        }
        vin.onblur=function(){
            if( this.value === '' ) {
                this.value = vinValueInitial;
                this.classList.remove('upper');
            }
        }
        vin.onkeyup=function(){
            this.value = this.value.toUpperCase();
        }
    }

    if (parts) {
        let partsValue = parts.value;
        parts.onfocus=function(e){
            if (this.value === partsValue) {
                this.value='';
            }
        }
        parts.onblur=function(){
            if( this.value === '' ) {
                this.value = partsValue;
            }
        }
    }

    if (vinForm) {
        vinForm.addEventListener('submit', (e) => {
            e.preventDefault();
            if ((vin.value.length < 17) || (vin.value === vinValueInitial) || (vin.value.toLowerCase().indexOf('wb'))) {
                document.getElementById('vin-search-form-error').classList.add('show');
            } else {
                let holder = document.querySelector('body');
                document.getElementById('vin-search-form-error').classList.remove('show');
                const wait4Vin = document.createElement('div');
                wait4Vin.id = 'vinSpinner';
                holder.classList.add('stopped');
                holder.appendChild(wait4Vin);
                e.currentTarget.submit();
            }
        });
    }

    let emptyTabs = $('.boeckmann-product-search .tabs-title[data-count="0"]');

    if (emptyTabs.length > 0) {
        emptyTabs.each(function() {
            $(this).on('click', function() {
                return false;
            })
        })
    }

    // remove asterisks from login form and add password visibility toggler
    const loginModal = document.getElementById('loginModal');
    let text = '';
    if (loginModal) {
        let inputLabels = loginModal.querySelectorAll('.inner.form-group label');
        inputLabels.forEach(function ($label) {
            text = $label.innerHTML.replace('*', '');
            $label.innerHTML = text;
        });
        let passwordInput = document.getElementById('customer_login_form__password');
        let passwordInputParent = passwordInput.parentElement;
        let toggler = document.createElement('div');
        toggler.classList.add('toggler');
        passwordInputParent.appendChild(toggler);
        toggler.addEventListener('click', (e) => {
            if (passwordInput.type === "password") {
                passwordInput.type = "text";
            } else {
                passwordInput.type = "password";
            }
        });
        let passwordInputFirst = document.getElementById('checkout_address_form_password_first');
        let passwordInputFirstParent = passwordInputFirst.parentElement;
        let togglerFirst = document.createElement('div');
        togglerFirst.classList.add('toggler');
        passwordInputFirstParent.appendChild(togglerFirst);
        togglerFirst.addEventListener('click', (e) => {
            if (passwordInputFirst.type === "password") {
                passwordInputFirst.type = "text";
            } else {
                passwordInputFirst.type = "password";
            }
        });
    }

    // remove asterisks from voucher label
    const voucherLabel = document.querySelector('.input-group.checkout-aside-add-code');
    let label = '';
    if (voucherLabel) {
        let requiredLabel = voucherLabel.querySelector('label.required');
        if (requiredLabel) {
            label = requiredLabel.innerHTML.replace('*', '');
            requiredLabel.innerHTML = label
        }
    }

    // password visibility toggler
    const passwordReset = document.querySelector('form[name="reset_password_form"]');
    if (passwordReset) {
        let passwordResetFirst = document.getElementById('reset_password_form_password_first');
        let passwordResetFirstParent = passwordResetFirst.parentElement;
        let togglerReset = document.createElement('div');
        togglerReset.classList.add('toggler');
        passwordResetFirstParent.style.position = 'relative';
        passwordResetFirstParent.appendChild(togglerReset);
        togglerReset.addEventListener('click', (e) => {
            if (passwordResetFirst.type === "password") {
                passwordResetFirst.type = "text";
            } else {
                passwordResetFirst.type = "password";
            }
        });
    }

    const customerLogin = document.querySelector('form[name="customer_login_form"]');
    if (customerLogin) {
        let customerLoginPassword = document.getElementById('customer_login_form__password');
        let customerLoginPasswordParent = customerLoginPassword.parentElement;
        let togglerLogin = document.createElement('div');
        togglerLogin.classList.add('toggler');
        customerLoginPasswordParent.style.position = 'relative';
        customerLoginPasswordParent.appendChild(togglerLogin);
        togglerLogin.addEventListener('click', (e) => {
            if (customerLoginPassword.type === "password") {
                customerLoginPassword.type = "text";
            } else {
                customerLoginPassword.type = "password";
            }
        });
    }

    const customerRegistration = document.querySelector('form[name="customer_registration_form"]');
    if (customerRegistration) {
        let registrationPassword = document.getElementById('customer_registration_form_password_first');
        let registrationPasswordParent = registrationPassword.parentElement;
        let togglerRegistration = document.createElement('div');
        togglerRegistration.classList.add('toggler');
        registrationPasswordParent.style.position = 'relative';
        registrationPasswordParent.appendChild(togglerRegistration);
        togglerRegistration.addEventListener('click', (e) => {
            if (registrationPassword.type === "password") {
                registrationPassword.type = "text";
            } else {
                registrationPassword.type = "password";
            }
        });
    }


    // notification banner
    const notificationBar = document.getElementById('notification-bar');
    if (notificationBar) {
        const storageBarKey = notificationBar.id + '-closed';
        const currentUrl = window.location.href;
        const mobileMetaSwitch = document.querySelector('.shop-header .site-toggle');
        let switcherPosition = '';
        const mainHeader = document.querySelector('.shop-header .main-header');
        let mainHeaderInitialHeight = mainHeader.clientHeight;
        const mobileMeta = document.querySelector('.shop-header .mobile-meta');
        const mobileMetaInitialTop = window.getComputedStyle(mobileMeta).getPropertyValue('top').replace("px", "");
        const mobileMenu = document.querySelector('.shop-header .mobile-menu');
        const iOSDevice = /^(iPhone|iPad|iPod)/.test(navigator.platform);
        if (window.sessionStorage.getItem(storageBarKey) === null) {
            notificationBar.classList.remove('hide');
            const close = notificationBar.querySelector('.close');
            close.addEventListener('click', (e) => {
                notificationBar.classList.add('popup-hidden');
                if (currentUrl.indexOf('pimcore_preview') === -1) {
                    window.sessionStorage.setItem(storageBarKey, '1');
                }
                mainHeader.style.height = mainHeaderInitialHeight + "px";
                mobileMeta.style.top = mobileMetaInitialTop + "px";
                switcherPosition = window.getComputedStyle(mobileMetaSwitch).getPropertyValue('position');
                if (switcherPosition === 'absolute') {
                    mobileMetaSwitch.style.top = mobileMetaInitialTop + "px";
                }
                if(iOSDevice) {
                    mobileMenu.style.height = 'calc(100vh - 5rem - 100px)';
                } else {
                    mobileMenu.style.height = 'calc(100vh - 5rem)';
                }
                mobileMenu.style.marginTop = '5rem';
            })
            check4Banner();
            window.addEventListener("resize", check4Banner);
        } else {
            notificationBar.remove();
        }

        function check4Banner() {
            let winWidth = window.innerWidth;
            switcherPosition = window.getComputedStyle(mobileMetaSwitch).getPropertyValue('position');
            let notificationHeight = notificationBar.clientHeight;
            switch(true) {
                case (winWidth > 1023):
                    mainHeaderInitialHeight = 122;
                    break;
                case (winWidth > 639):
                    mainHeaderInitialHeight = 86;
                    break;
                default:
                    mainHeaderInitialHeight = 80;
            }
            mainHeader.style.height = mainHeaderInitialHeight + notificationHeight + "px";
            mobileMeta.style.top =  parseInt(mobileMetaInitialTop) + notificationHeight + "px";
            if (switcherPosition === 'absolute') {
                mobileMetaSwitch.style.top =  parseInt(mobileMetaInitialTop) + notificationHeight + "px";
            } else {
                mobileMetaSwitch.style.top =  0;
            }
            if(iOSDevice) {
                mobileMenu.style.height = 'calc(100vh - 5rem - 100px - ' + notificationHeight + 'px)';
            } else {
                mobileMenu.style.height = 'calc(100vh - 5rem - ' + notificationHeight + 'px)';
            }
            let calcTop = 5 * 16 + notificationHeight;
            mobileMenu.style.marginTop = calcTop + 'px';
        }
    }

    // side navigation
    const sideNavigation = document.querySelector('.category-content .category-content-aside');
    if (sideNavigation) {
        let level2Items = sideNavigation.querySelectorAll('.category-navigation-link .arrow');
        let parentItem;
        let toggleItem;
        let isActive;
        level2Items.forEach(function(element) {
            parentItem = element.parentNode;
            if (parentItem.parentNode.classList.contains('active') || parentItem.parentNode.classList.contains('trail')) {
                parentItem.classList.add('open');
            }
            element.addEventListener('click', (e) => {
                e.preventDefault();
                parentItem = element.parentNode;
                if (parentItem.parentNode.classList.contains('active') || parentItem.parentNode.classList.contains('trail')) {
                    isActive = true;
                } else {
                    isActive = false;
                }
                toggleItem = parentItem.nextElementSibling;
                if (toggleItem.classList.contains('fold') && isActive) {
                    toggleItem.classList.remove('fold');
                    parentItem.classList.add('open');
                } else if (toggleItem.classList.contains('show-links')) {
                    toggleItem.classList.remove('show-links');
                    parentItem.classList.remove('open');
                } else if (isActive) {
                    toggleItem.classList.add('fold');
                    parentItem.classList.remove('open');
                } else {
                    toggleItem.classList.add('show-links');
                    parentItem.classList.add('open');
                }
            })
        });
    }

    // create select for order overview
    const orderOverview = document.querySelector('.shop .account-content-main .order-group');
    if (orderOverview) {
        let singleYear;
        const allYears = [];
        let allOrders = orderOverview.parentNode.querySelectorAll('[data-year]');
        if (allOrders.length > 1) {
            allOrders.forEach(function(element) {
                singleYear = element.dataset.year;
                if (!allYears.includes(singleYear)) {
                    allYears.push(singleYear);
                }
            });
            allYears.sort();

            let selectedYear;
            const selectHolder = document.getElementById('yearSelect');
            const yearSelect = document.createElement('select');
            yearSelect.classList = 'select-year';
            yearSelect.id = 'singleYears';
            let options = '<option value="all">' + selectHolder.dataset.allorders + '</option>';
            allYears.forEach( function(year) {
                options += '<option value="' + year + '">' + year + '</option>';
            });
            yearSelect.innerHTML = options;
            selectHolder.appendChild(yearSelect);
            selectHolder.querySelector('.select-headline').classList.remove('hide');
            delay(100).then(() => {
                let $years = $('#yearSelect select').selectize({
                    closeAfterSelect: true,
                    onChange        : changeYear()
                });
                selectedYear = $years[0].selectize;
            });

            let changeYear = function() {
                return function() {
                    if (selectedYear.getValue() === 'all') {
                        allOrders.forEach(element => element.classList.remove('hide'));
                    } else {
                        allOrders.forEach( function(elem) {
                            if(elem.dataset.year === selectedYear.getValue()) {
                                elem.classList.remove('hide');
                            } else {
                                elem.classList.add('hide');
                            }
                        });
                    }
                };
            };
        }

    }

    let passwordInputs = document.querySelectorAll('#customer_registration_form_password_first, #change_password_form_new_password_first, #checkout_address_form_password_first, #reset_password_form_password_first');
    if (passwordInputs.length > 0) {
        for (let input of passwordInputs) {
            checkPassword(input);
        }
    }

    /*
    * workaround regarding chrome bug(?) => when using back button the page is often not loaded correctly
    * because of that => we force removal of vin spinner if it's still there...
    * */
    window.addEventListener('pageshow', (event) => {
        let spinLoader = document.getElementById('vinSpinner');
        if (spinLoader) {
            spinLoader.remove();
            spinLoader = null;
            document.body.classList.remove('stopped');
        }
    });

    let switcher = $('.language-selector.select-wrapper .language-select');
    switcher.each( function() {
        $(this).selectize();
    })

});

function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

function checkPassword(input) {
    let position;
    input.onkeyup=function(){
        position = this.value.search('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!()@$%&*-]).{8,}$');
        if (position < 0) {
            this.setCustomValidity('less strength');
        } else {
            this.setCustomValidity('');
        }
    }
}

const forbiddenChars = /[#|„“"']/g;
const excludedIds = ['contact_form_on_request_cart_customerOrderNumber', 'checkout_address_form_password_first', 'customer_registration_form_password_first'];
const formNames = ['contact_form_on_request_cart', 'checkout_address_form', 'customer_registration_form'];

document.addEventListener('keypress', function (event) {
    if (event.target.tagName === 'INPUT') {
        let forms = document.querySelectorAll('form');

        forms.forEach(form => {
            if (form.name && formNames.includes(form.name)) {
                if (form.contains(event.target) && !excludedIds.includes(event.target.id)) {
                    const char = String.fromCharCode(event.which);
                    if (forbiddenChars.test(char)) {
                        event.preventDefault();     
                    }
                }
            }
        });
    }
});


document.addEventListener('input', function(event) {
    if (event.target.tagName === 'INPUT') {
        let forms = document.querySelectorAll('form');

        forms.forEach(form => {
            if (form.name && formNames.includes(form.name)) {
                if (form.contains(event.target) && !excludedIds.includes(event.target.id)) {
                    event.target.value = event.target.value.replace(forbiddenChars, '');
                }
            }
        });
    }
});

