// form styling helper
import $ from "jquery";


var getScrollOffset = function () {
    let productHeader = $('.p-d-navigation')
    let header = $('.inside-header');

    let offset = 0;
    if (header.length > 0 && header.is(':visible')) {
        offset = header.outerHeight()
    }
    if (productHeader.length > 0 && productHeader.is(':visible')) {
        offset = productHeader.outerHeight()
    }

    offset += 50;

    return offset;
}

$( document ).ready(function() {

    $('.form-uncheck-all').click(function() {
        $(this).parents('form').find('[type="checkbox"]').prop('checked', false);
    })

    var form = $('.boeckmann-form--moving-labels');
    var fields = form.find('input, textarea');

    if (form.length <= 0) {
        return;
    }

    // by click on an error message jump to form input on error
    (function () {
        $('.error-message a').on('click', function (e) {
            e.preventDefault();
            let input = $(this.getAttribute('href'));
            if (input.is(':hidden')) {
                input = input.parent()
            }

            $([document.documentElement, document.body]).animate({
                scrollTop: input.offset().top - getScrollOffset()
            }, 500)
        });
    })();

    // jump to form errors on inital load
    (function () {
        let errorMesssageContainer = $('.error-message');
        if (errorMesssageContainer.length <= 0) {
            return;
        }

        setTimeout(function () {
            $([document.documentElement, document.body]).animate({
                scrollTop: errorMesssageContainer.offset().top - getScrollOffset()
            }, 500);
        }, 200)
    })();

    fields.each(function () {
        if ($(this).val()) {
            $(this).parent().addClass('filled')
        }

        fields.on('focus', function () {
            $(this).parent().addClass('filled');
        });
        fields.on('blur', function () {
            if ($(this).val())
                $(this).parent().addClass('filled');
            else
                $(this).parent().removeClass('filled');
        })
    });
    if(form.find('.selectize-container').length > 0) {
        var selects = form.find('.selectize-container select');

        selects.each(function() {
            if ( $(this).val() )
                $(this).parent().addClass('selected');
            $(this).on('change', function() {
                if ( $(this).val() ) {
                    $(this).parent().addClass('selected');
                } else {
                    $(this).parent().removeClass('selected');
                }
            })
        })
    }

});
