import $ from 'jquery';

window.$ = $;

import 'jquery-clickout';

$('.trigger-mega, .mega-layer').on('clickout', function(e){
    $('.mega-layer').removeClass('opened');
    $('.bg-layer').removeClass('open');
    $('.level01').removeClass('active');
    $('.sticky-container').height('auto');
});