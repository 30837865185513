
// import Swiper JS
import Swiper from 'swiper/swiper-bundle';

const shopProductSwiper = new Swiper('.shop-product-slider-swiper', {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 40,
    loop: true,
    breakpoints: {
        640: {
            slidesPerView: 3,
            groupPerView: 3,
        },
        1024: {
            slidesPerView: 4,
            groupPerView: 4,
        }
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    }
});

let campaignDefaults = {
    loop: true,
    height: 460,
    pagination: {
        clickable: true,
        el: '.swiper-pagination',
    }
};
// call init function
initCampaignSwipers(campaignDefaults);

function initCampaignSwipers(campaignDefaults = {}, selector = ".shop-campaign-swiper") {
    let swipers = document.querySelectorAll(selector);
    swipers.forEach((swiper) => {
        let optionsData = swiper.dataset.swiper
            ? JSON.parse(swiper.dataset.swiper)
            : {};
        let options = {
            ...campaignDefaults,
            ...optionsData
        };
        //console.log(options);
        new Swiper(swiper, options);
    });
}

const productPageThumbs = new Swiper('.product-page-thumbs', {
    spaceBetween: 10,
    slidesPerView: 5,
    freeMode: true,
    watchSlidesProgress: true,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});

const productPageSwiper = new Swiper('.product-page-slider', {
    spaceBetween: 10,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    thumbs: {
        swiper: productPageThumbs,
    },
    breakpoints: {
        1024: {
            navigation: {
                enable: false
            }
        }
    }
});

const shopRecommendationSwiper = new Swiper('.shop-product-recommendations-swiper', {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 40,
    loop: true,
    breakpoints: {
        640: {
            slidesPerView: 2,
            groupPerView: 2,
        },
        1024: {
            slidesPerView: 3,
            groupPerView: 3,
        }
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    }
});
