import 'url-search-params-polyfill';
import $ from "jquery";

import productFinderLight from './app/productfinderlight';

// loads every js script defined in mo
import './app/modules/index.js';

$(document).ready(function () {
    const wraps = $('.product-finder-light-wrap');
    wraps.each(function (i, element) {
        productFinderLight($(element))
    });

    if( /iPhone/i.test(navigator.userAgent) ) {
        $('html').addClass('nozoom');
    }

    // set scrollfunction to anchor links
    if($('.scroll2').length > 0) {
        $('.scroll2').on('click', function(e) {
            e.preventDefault();
            var target = $(this).attr('href');
            scrollTarget(target);
        });
    }

    if($('.scroll-down')) {
        $('.main__content').attr('id', 'mainContent');
        $('.scroll-down').on('click', function(e) {
            e.preventDefault();
            $('.main__content').attr('id', 'mainContent');
            scrollTarget('#mainContent');
        })
    }
    enableGTM();
    window.addEventListener('ccm19WidgetClosed', function (e) {
        enableGTM();
    });
});

function scrollTarget(target) {
    var navHeight = $('.page .page__header').outerHeight();
    var targetPosition;
    targetPosition = parseInt($(target).offset().top - (navHeight + 30));
    $('html,body').animate({scrollTop: targetPosition},500);
}

function enableGTM() {
    if (CCM && typeof (CCM.acceptedEmbeddings.find(element => element.name == 'Google Tag Manager')) !== 'undefined') {
        $('#gtmscript').each(function () {
            $(this).after(this.outerHTML.replace('text/plain', 'text/javascript')).remove();
        });
    }
}

import '../scss/app.scss';

