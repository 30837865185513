import $ from "jquery";

export default function initSelectize(parent) {
    if (typeof parent === "undefined") {
        parent = $('body')
    }

    parent.find('.selectize-origin-select').each(function () {
        let select = $(this);
        let selectize = null;
        select.selectize({
            closeAfterSelect: true,
            onItemRemove: function (value, item) {
                $(this).trigger('change');
            },
        });

        selectize = select[0].selectize;

        if (selectize.$control.parent().hasClass('multi')) {
            selectize.$control.find('.item').on('mousedown', function (e) {
                e.stopPropagation();
            });
            selectize.$control.find('.item').on('click', function (e) {
                selectize.removeItem($(this).data('value'));
                e.stopPropagation();
            });
        }
    });
}