import $ from 'jquery';

window.$ = $;


// Desktop Navigation
$(window).on('load resize', function () {
    let leftPos = $('.logo').offset().left,
        navigationLayerWidth = $('.mega-layer').width();

    $('.mega-layer').css('padding-left', leftPos + 'px').data('left', leftPos);
    $('.subnavigation-box').css('left', (navigationLayerWidth + leftPos) + 'px');
});

$(document).ready(function() {
    let hideBT;
    $('.page__header .searchbox-layer').css('display', 'flex');
    $('.inner .level01.is-drilldown-submenu-parent > a').on('click', function() {
        $('.toggle-container').hide();
    });
    $('.inner .js-drilldown-back a').on('click', function() {
        $('.toggle-container').show();
        hideBT = $(this).parent().siblings().find('button');
        hideBT.each(function() {
            if($(this).attr('aria-expanded') === 'true') {
                $(this).trigger('click');
            }
        })
    });
});

$('.trigger-mega').on('click', function (e) {
    e.preventDefault();
    let layerId = $(this).data('layer'),
        leftPosNavigationItem = $(this).offset().left,
        navigationLayer = $('#navigationLayer-' + layerId),
        navigationLayerWidth = parseInt(leftPosNavigationItem + $(this).width() + 40) + 'px',
        navigationBoxHeight = parseInt(navigationLayer.find('.navigation-box').height() + 40) + 'px',
        navigationWidth = 0,
        sectionWidth = 0;

    $('.mega-layer').removeClass('opened');
    $('.level01').removeClass('active');

    navigationLayer.addClass('opened');
    navigationWidth = parseInt(navigationLayer.width() + navigationLayer.data('left'));
    navigationLayer.find('.subnavigation-box').css('left',navigationWidth);
    navigationLayer.css('min-height','440px');//navigationBoxHeight

    sectionWidth = navigationLayer.find('.section-box').width();

    $(this).parent().addClass('active');

    $('.main-header').addClass('opened');
    $('.bg-layer').addClass('open');
    return false;
});


$('.mega-layer .trigger-sub').on('click', function (e) {
    e.preventDefault();
    let layerId = $(this).data('layer');

    $('.subnavigation-box').removeClass('opened');
    $('.subnavigation-link').removeClass('active');
    let subNav = $('#subnavigationLayer-' + layerId);
    subNav.addClass('opened');

    $(this).parent().addClass('active');
    return false;
});

// Search box opener

var closeSearchbox = function () {
    $('.page__header').removeClass('search-opened');
    $('.search-opener').data('search-opened', false);
    $('.main-header').removeClass('opened');
}

var openSearchbox = function () {
    $('.page__header').addClass('search-opened');
    $('.search-opener').data('search-opened', true);
    $('.bg-layer').removeClass('open');
}

$('.search-opener').on('click', function () {
    if ($(this).data('search-opened') === true) {
        closeSearchbox();
    } else {
        openSearchbox();
    }
});

$('.searchbox-closer').on('click', function () {
    closeSearchbox();
});


document.addEventListener("keyup", function (e) {
    if (e.keyCode == 27) {
        closeSearchbox();
    }
})


$('.language-select').on('change', function (e) {
    window.location.href = $(this).val();
});

// mobile Navigation

// open mobile menu
$('#switch, #switchText').on('click', function () {
    var body = $('body');

    if (body.hasClass('nav-open')) {
        body.removeClass('nav-open');
    } else {
        body.addClass('nav-open');
    }
});

let iOS = /^(iPhone|iPad|iPod)/.test(navigator.platform);
if(iOS) {
    $('.mobile-menu').addClass('ios');
}
