export default function getFormValuesByParent(form) {
    let dataObj = {};
    const a = form.find('[name]').map(function (i, element) {
        if ($(element).attr('type') == 'radio') {
            if ($(element).is(':checked')) {
                return {
                    name: $(element).attr('name'),
                    value: $(element).val(),
                };
            }
        }else if($(element).val() != ''){
            return {
                name: $(element).attr('name'),
                value: $(element).val(),
            };
        }
    });
    $.each(a, function () {
        let selector = '[name="'+ this.name +'"]';
        var element = form.find(selector)

        let names = [];
        let rootName;
        if (this.name.indexOf('[') === -1) {
            rootName = this.name;
            names.push(rootName);
        } else {
            rootName = this.name.split('[')[0];
            names = this.name
                .replace(rootName, '')
                // remove all [ chars
                .replace(/\[/g, '')
                // split string on ]
                .split(']')
                // filter empty values
                .filter((el) => {return el !== ''});

            names.unshift(this.name.split('[')[0]);
        }

        createObject(dataObj, names, 0, this.value);
    })

    return dataObj;
}

function createObject(obj, names, i, value) {
    if (i < names.length - 1) {
        if (!obj.hasOwnProperty(names[i])) {
            obj[names[i]] = {};
        }
        obj = createObject(obj[names[i]], names, i + 1, value);
    } else {
        obj[names[i]] = value;
    }
    return obj;
}