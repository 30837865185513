import $ from 'jquery';

window.$ = $;

import Masonry from 'masonry-layout';

$( document ).ready(function() {

    if($('.p-d-equipment').length > 0) {
        var masons = $('.p-d-equipment .mason');
        var mason;

        var n = masons.length;
        for(var i = 0; i < n; i++){
            mason = new Masonry( masons[i]);
        }
    }

});
