import $ from 'jquery';

window.$ = $;

import 'slick-carousel';

// slick on mobile
/* ToDo
    // ↓ True for "medium" or larger
    Foundation.MediaQuery.is('medium down');
    Foundation.MediaQuery.upTo('medium');
    upTo is not working at the moment and medium down delivers wrong values
 */
function slick_on_mobile(slider, settings, atleast, rangeFrom, rangeTo ){
    $(window).on('load resize', function() {
        window.setTimeout(function(){
            if (Foundation.MediaQuery.atLeast(atleast) && slider.hasClass('slick-initialized')) {
                slider.slick('unslick');
            } else if (!slider.hasClass('slick-initialized') && (Foundation.MediaQuery.is(rangeFrom) || Foundation.MediaQuery.is(rangeTo))) {
                return slider.slick(settings);
            }
        }, 500);
    });
}

function slick_slidesToScroll(slider, slidesToScrollSmall, slidesToScrollMedium, slidesToScrollLarge, slidesToScrollXLarge ){
    $(window).on('load resize', function() {
        window.setTimeout(function(){
            if(slider.hasClass('slick-initialized')) {
                if (Foundation.MediaQuery.atLeast('small')){
                    imageSlider.slick('slickSetOption', 'slidesToScroll', slidesToScrollSmall, true);
                }
                if (Foundation.MediaQuery.atLeast('medium')){
                    imageSlider.slick('slickSetOption', 'slidesToScroll', slidesToScrollMedium, true);
                }
                if (Foundation.MediaQuery.atLeast('large')){
                    imageSlider.slick('slickSetOption', 'slidesToScroll', slidesToScrollLarge, true);
                }
                if (Foundation.MediaQuery.atLeast('xlarge')){
                    imageSlider.slick('slickSetOption', 'slidesToScroll', slidesToScrollXLarge, true);
                }

            }
        }, 500);
    });
}

function overflowButtons(slider) {
    $('.slick-next-after').on('click', function(){
        slider.slick('slickNext');
    });
    $('.slick-prev-before').on('click', function(){
        slider.slick('slickPrev');
    });
}

var heroTeaserSlider = $('.hero-teaser-slider');

if(heroTeaserSlider.length > 0){

    var settingsHeroTeaserSlider = {
            dots: true,
            arrows: false,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            mobileFirst: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        arrows: true,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        arrows: true,
                    }
                }
            ]
        };

    heroTeaserSlider.slick(settingsHeroTeaserSlider);
}

var imageSlider = $('.image-slider'),
    imageSlides = $('.image-slider').find('.image-slider-slide');


if(imageSlider.length > 0){

    var settingsImageSlider = {
        dots: true,
        mobileFirst: false,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 2,
        slidesToScroll: 2,
        adaptiveHeight: true,
        draggable: true,
        accessibility: true,
        centerMode: false,
        variableWidth: true,
        rows: 1,
        swipeToSlide: true,
        prevArrow: '<span class="slick-prev-before"></span><button class="slick-prev slick-arrow" aria-label="Previous" type="button">Previous</button>',
        nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button">Next</button><span class="slick-next-after"></span>',
        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    arrows: true,
                    variableWidth: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    variableWidth: false,
                }
            }
        ]
    };
    $.each( imageSlider, function( key, slider ){
        var imageSliderId = $('#'+ $(slider).attr('id'));
        imageSliderId.slick(settingsImageSlider);
        slick_slidesToScroll(imageSliderId, 1, 1, 1, 2 );
        overflowButtons(imageSliderId);
    } );
}

var productDetailSlider = $('.p-d-slider .slick-slider');

if(productDetailSlider.length > 0){
    var settingsProductDetailSlider = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 600,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: false,
        fade: false,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: true,
                    fade: true,
                    cssEase: 'linear'
                }
            }
        ]
    };

    productDetailSlider.slick(settingsProductDetailSlider);
}

var heroTeaserProductSlider = $('.p-d-added-value-hero-slider .boeckmann-slider');

if(heroTeaserProductSlider.length > 0){

    var settingsHeroTeaserProductSlider = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: true,
                    arrows: true,
                }
            }
        ]
    };

    heroTeaserProductSlider.slick(settingsHeroTeaserProductSlider);
}

var productDoubleSlider = $('.p-d-added-value-double-slider .slick-slider');

if(productDoubleSlider.length > 0){
    var settingsProductDoubleSlider = {
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: false,
        centerMode: false,
        mobileFirst: true,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: true,
                    arrows: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '134px'
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '40px'
                }
            }
        ]
    };

    productDoubleSlider.slick(settingsProductDoubleSlider);
}

var offerSliderNew = $('.offer-teaser-new .slick-slider');

if(offerSliderNew.length > 0){
    var settingsOfferSliderNew = {
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        adaptiveHeight: false,
        centerMode: false,
        mobileFirst: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: true,
                    swipeToSlide: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '20vw'
                }
            },
            {
                breakpoint: 768,
                settings: {
                    dots: false,
                    swipeToSlide: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '10vw'
                }
            },
            {
                breakpoint: 640,
                settings: {
                    dots: false,
                    swipeToSlide: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false
                }
            }
        ]
    };

    offerSliderNew.slick(settingsOfferSliderNew);
}

var productDetailPackagesSlider = $('.p-d-packages-slider .slick-slider');

if(productDetailPackagesSlider.length > 0){

    var settingsProductDetailPackagesSlider = {
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: false,
        centerMode: false,
        mobileFirst: true,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    dots: true,
                    arrows: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '134px'
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '40px'
                }
            }
        ]
    };

    productDetailPackagesSlider.slick(settingsProductDetailPackagesSlider);
}
