//import jquery from 'jquery';
import $ from 'jquery';

import magnificPopup from 'magnific-popup';
import * as DOMPurify from 'dompurify';
window.$ = $;

$('.modal-link').magnificPopup({
    type: 'image',
    image: {
        titleSrc: function(item) {
            var markup = '';
            if (item.el[0].hasAttribute('data-caption')) {
                markup = escapeXSS(item.el.attr('data-caption'));

            } else {
                markup = escapeXSS(item.el.attr('title'));

            }
            return markup;
        }
    },
    midClick: true,
    callbacks: {
        open: function() {
            let shopZoom = $.magnificPopup.instance.st.el.attr('data-shop');
            if((typeof shopZoom !== 'undefined') && (shopZoom.length > 0)) {
                $('.mfp-container').addClass(shopZoom);
            }
        },
        close: function() {
            var video = $('.video-frame .video-teaser');
            if(video.length > 0) {
                video.removeClass('video-is-playing');
            }
        }
    }
});

$('.mp-gallery').each(function() {
    $(this).magnificPopup({
        gallery: {
            enabled: true,
            arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
            tPrev: 'Previous',
            tNext: 'Next',
            tCounter: ''
        },
        navigateByImgClick: false,
        delegate: '.slick-slide:not(.slick-cloned) .hero-teaser-slide',
        type: 'image',
        image: {
            titleSrc: function(item) {
                var markup = '';
                if (item.el[0].hasAttribute('data-caption')) {
                    markup = escapeXSS(item.el.attr('data-caption'));

                } else {
                    markup = escapeXSS(item.el.attr('title'));

                }
                return markup;
            }
        },
        midClick: true,
        callbacks: {
            close: function() {
                var video = $('.video-frame .video-teaser');
                if(video.length > 0) {
                    video.removeClass('video-is-playing');
                }
            }
        }
    });
});
/*
 * To escape xss injection
 */
const  escapeXSS = (text) => {
    return DOMPurify.sanitize(text);
}