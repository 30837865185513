import $ from 'jquery';
import Foundation from "foundation-sites";
import 'babel-polyfill'
import productFinderLight from '../../app/productfinderlight';

var initializedProductFinders = [];

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function changeAmount(compare_product_ids) {

    let count = 0;
    count += compare_product_ids['pkw'].length;
    count += compare_product_ids['lkw'].length;
    count += compare_product_ids['pfa'].length;
    count += compare_product_ids['vie'].length;

    $('.product-compare-badge').find('.product-compare-counter').html(count);
    if (count == 0) {
        $('.product-compare-badge').hide();
    } else {
        $('.product-compare-badge').show();
    }

    if (compare_product_ids['pkw'].length > 2) {
        jQuery('.product-compare-add[data-category="pkw"]:not(.active)').addClass('disabled');
    } else {
        jQuery('.product-compare-add[data-category="pkw"]').removeClass('disabled');
    }

    if (compare_product_ids['lkw'].length > 2) {
        jQuery('.product-compare-add[data-category="lkw"]:not(.active)').addClass('disabled');
    } else {
        jQuery('.product-compare-add[data-category="lkw"]').removeClass('disabled');
    }

    if (compare_product_ids['pfa'].length > 2) {
        jQuery('.product-compare-add[data-category="pfa"]:not(.active)').addClass('disabled');
    } else {
        jQuery('.product-compare-add[data-category="pfa"]').removeClass('disabled');
    }

    if (compare_product_ids['vie'].length > 2) {
        jQuery('.product-compare-add[data-category="vie"]:not(.active)').addClass('disabled');
    } else {
        jQuery('.product-compare-add[data-category="vie"]').removeClass('disabled');
    }

}

function selectVergleichen(product_id) {
    $('.product-compare-add[data-artno=' + product_id + ']').addClass('active');
}

function deselectVergleichen(product_id) {
    $('.product-compare-add[data-artno=' + product_id + ']').removeClass('active');
}

function initCompareProduct() {
    let compare_product_ids = [];

    compare_product_ids['pkw'] = getCookie('compare-product-ids-pkw').split(',');
    if (compare_product_ids['pkw'][0] == '') compare_product_ids['pkw'] = [];
    compare_product_ids['lkw'] = getCookie('compare-product-ids-lkw').split(',');
    if (compare_product_ids['lkw'][0] == '') compare_product_ids['lkw'] = [];
    compare_product_ids['pfa'] = getCookie('compare-product-ids-pfa').split(',');
    if (compare_product_ids['pfa'][0] == '') compare_product_ids['pfa'] = [];
    compare_product_ids['vie'] = getCookie('compare-product-ids-vie').split(',');
    if (compare_product_ids['vie'][0] == '') compare_product_ids['vie'] = [];

    return compare_product_ids;
}

function saveCompareProducts(compare_product_ids) {
    setCookie('compare-product-ids-pkw', compare_product_ids['pkw'], 30);
    setCookie('compare-product-ids-lkw', compare_product_ids['lkw'], 30);
    setCookie('compare-product-ids-pfa', compare_product_ids['pfa'], 30);
    setCookie('compare-product-ids-vie', compare_product_ids['vie'], 30);
}

function updateCurrentCategory(category) {
    setCookie('compare-product-current-category', category, 30);
}

async function addCompareProducts(compare_product_ids, category, id) {
    await compare_product_ids[category].push(id);
    await selectVergleichen(id);
    await saveCompareProducts(compare_product_ids);
    await changeAmount(compare_product_ids);
    updateCurrentCategory(category)
    return compare_product_ids;
}

async function deleteCompareProducts(compare_product_ids, category, id) {
    await compare_product_ids[category].splice(compare_product_ids[category].indexOf(id), 1);
    await deselectVergleichen(id);
    await saveCompareProducts(compare_product_ids);
    await changeAmount(compare_product_ids);
    return compare_product_ids;
}

function initCompareProductsBadge(compare_product_ids) {
    compare_product_ids['pkw'].forEach(function (item) {
        selectVergleichen(item);
    })
    compare_product_ids['lkw'].forEach(function (item) {
        selectVergleichen(item);
    })
    compare_product_ids['pfa'].forEach(function (item) {
        selectVergleichen(item);
    })
    compare_product_ids['vie'].forEach(function (item) {
        selectVergleichen(item);
    })
    changeAmount(compare_product_ids);
}

function loadModal(reset) {
    $.ajax('/' + $('html').first().attr('lang') + '/compare').done(function (resp) {
        let modal = $('#product-compare-modal');
        modal.html(resp).addClass('reveal');
        if(reset != 'reset') {
            var popup = new Foundation.Reveal($('#product-compare-modal'));
            popup.open();
        }
        const lastUpdatedCategory = getCookie('compare-product-current-category');

        let tab = modal.find('.product-compare-table-item[data-category="'+ lastUpdatedCategory +'"]')
        if (tab.length <= 0) {
            tab = $('.product-compare-table-item')[0];
        }

        const category = tab.data('category');
        $('#product-compare-table-dropdown').val(category);
        tab.removeClass('hide');
        productFinderLight(tab.find('.product-finder-light-wrap'));
        initializedProductFinders = [category];
    });
}

$(document).ready(function () {

    let compare_product_ids = initCompareProduct();
    initCompareProductsBadge(compare_product_ids);

    $('.product-compare-add').click(function () {

        const id = $(this).data('artno').toString();
        const category = $(this).data('category').toString();

        if (!compare_product_ids[category].includes(id)) {
            if (compare_product_ids[category].length <= 2) {
                addCompareProducts(compare_product_ids, category, id)
            }
        } else {
            deleteCompareProducts(compare_product_ids, category, id)
        }

    });

    $('.product-compare-badge').click(function () {
        loadModal();
    })

    $(document).on('click', '.product-compare-table-item-delete', async function () {
        const category = $(this).data('category');
        const id = $(this).data('artno').toString();
        await deleteCompareProducts(compare_product_ids, category, id)
        await loadModal('reset');
    });

    $(document).on('change', '#product-compare-table-dropdown', function () {
        const category = $(this).val();
        const nextCatContainer = $('.product-compare-table-item[data-category=' + category + ']');
        $('.product-compare-table-item').addClass('hide');
        nextCatContainer.removeClass('hide');
        updateCurrentCategory(category);

        let productFinder = $(nextCatContainer).find('.product-finder-light-wrap');
        if (initializedProductFinders.indexOf(category) === -1 && productFinder.length > 0) {
            productFinderLight(productFinder);
            initializedProductFinders.push(category);
        }

    });

});
