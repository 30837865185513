import $ from 'jquery';

$( document ).ready(function() {

    //set class for touch device
    if(is_touch_device()) {
        $('html').addClass('touch');
    }

    // sticky sub navigation
    if($('#prodNav').length > 0) {

        var pdNavHeight = $('#prodNav').outerHeight();
        var winHeight = $(window).height();

        if(winHeight > (pdNavHeight * 3)) {

            // scroll and set buttons active (like foundations magellan)

            var pdNavTop = parseInt($('#prodNav').offset().top - 40);
            var pdStickyNav = function() {
                var techdataTop = parseInt($('#pdTechdata').offset().top - pdNavHeight - 30);
                var techdataHeight = $('#pdTechdata').outerHeight();
                var overviewTop = 0;
                var overviewHeight = techdataTop;
                var scrollTop = $(window).scrollTop();
                if (scrollTop > pdNavTop) {
                    $('#prodNav').addClass('sticky');
                    $('.page .page__header').hide();
                    $('#mainContent').css('padding-top', pdNavHeight + 'px');
                } else {
                    $('#prodNav').removeClass('sticky');
                    $('.page .page__header').show();
                    $('.sticky-container .sticky').css('max-width', $(window).width() + 'px');
                    $('#mainContent').css('padding-top', '0');
                }
                if ((scrollTop > techdataTop) && (scrollTop < parseInt(techdataTop + techdataHeight))) {
                    $('.button[href="#pdOverview"]').removeClass('is-active');
                    $('.button[href="#pdTechdata"]').addClass('is-active');
                } else if (scrollTop < parseInt(overviewTop + overviewHeight)) {
                    $('.button[href="#pdTechdata"]').removeClass('is-active');
                    $('.button[href="#pdOverview"]').addClass('is-active');
                } else {
                    $('.button[href="#pdTechdata"]').removeClass('is-active');
                    $('.button[href="#pdOverview"]').removeClass('is-active');
                }
            };
            pdStickyNav();

            if($('.button.scroll').length > 0) {
                $('.button.scroll').on('click', function(e) {
                    e.preventDefault();
                    var target = $(this).attr('href');
                    scrollDetail(target);
                });
            }

            var scrollDetail = function(target) {
                var prodNavMargin = parseInt($('#prodNav').css('margin-bottom'));
                var navHeight = $('.page .page__header').outerHeight();
                if($('#prodNav').css('position') == 'fixed') {
                    navHeight = $('#prodNav').outerHeight();
                } else {
                    navHeight = navHeight + parseInt($('#prodNav').outerHeight() + prodNavMargin);
                }
                var targetPosition;
                if(target === '#pdOverview') {
                    targetPosition = 0;
                } else {
                    targetPosition = parseInt($(target).offset().top - navHeight - 10);
                }
                $('html,body').animate({scrollTop: targetPosition},500);
            };

            $(window).scroll(function() {
                pdStickyNav();
            });

        } else {
            $('.button[href="#pdOverview"]').addClass('is-active');

            if($('.button.scroll').length > 0) {
                $('.button.scroll').on('click', function(e) {
                    e.preventDefault();
                    var navHeight = $('.page .page__header').outerHeight();
                    var target = $(this).attr('href');
                    var targetPosition;
                    if(target === '#pdOverview') {
                        targetPosition = 0;
                    } else {
                        targetPosition = parseInt($(target).offset().top - navHeight - 10);
                    }
                    $('html,body').animate({scrollTop: targetPosition},500);
                });
            }
        }

    }

    if($('.sale-list-filter .list-view').length > 0 ) {
        let switchButtons = $('.sale-list-filter button');
        $('form').append('<input type="hidden" name="gridActiveField" value="' + $('#showGrid').hasClass('is-active') + '" />');
        switchButtons.each(function() {
            $(this).on('click', function() {
                switchView($(this).attr('id'));
            })
        })
    }

    $('.list-table *[data-href]').on('click',function(e) {
        window.location = $(this).data('href');
        return false;
    });

    // equalize height (foundation equalizer not working if measured from bottom or different number of elements are inside the watched container)
    if ($(window).width() > 1023) {
        $('.p-d-added-value-double-slider').each(function(){
            var layerHeight = 0;
            $('.mp-gallery .text-layer', this).each(function(){
                if($(this).height() > layerHeight) {
                    layerHeight = $(this).height();
                }
            });
            $('.mp-gallery .text-layer', this).height(layerHeight);
        });
    }

    heightEqualize('.sale-list-wrapper', '.product-grid-item-shadowwrapper', 639);
    heightEqualize('.distribution-page.level-2', '.text-layer', 639);

    function heightEqualize(parent, child, width) {
        if ($(window).width() > width) {
            $(parent).each(function(){
                $(child, this).height('initial');
                var contentHeight = 0;
                $(child, this).each(function(){
                    if($(this).height() > contentHeight) {
                        contentHeight = $(this).height();
                    }
                });
                $(child, this).height(contentHeight);
            });
        } else {
            $(parent).each(function(){
                $(child, this).height('initial');
            });
        }
    }

    $( window ).resize(function() {
        heightEqualize('.sale-list-wrapper', '.product-grid-item-shadowwrapper', 639);
        heightEqualize('.distribution-page.level-2', '.text-layer', 639);
    });

    function delayedResize() {
        heightEqualize('.sale-list-wrapper', '.product-grid-item-shadowwrapper', 639);
    }

    function switchView(view) {
        if(!$('#' + view).hasClass('is-active')) {
            setTimeout(function(){ delayedResize(); }, 5);
            if(view === 'showList') {
                $('form input[name ="gridActiveField"]').val('false');
            } else {
                $('form input[name ="gridActiveField"]').val('true');
            }
        }
    }

    function is_touch_device() {
        try {
            document.createEvent("TouchEvent");
            return true;
        } catch (e) {
            return false;
        }
    }

    // move layer in top direction
    if ($(window).width() > 1023) {
        var topic;
        var text;
        var layer;
        var allHeight;
        var movingLayer = $('.p-d-added-values .item > a');
        movingLayer.each(function(){
            $(this).hover(function() {
                topic = $(this).find('.topic');
                text = $(this).find('p');
                layer = $(this).find('.addval-teaser-layer');
                allHeight = parseInt(topic.outerHeight() + text.outerHeight() + 58);
                $(layer).css('margin-top', (allHeight)*-1 + 'px' );
            }, function() {
                $(layer).css('margin-top', '-52px' );
            })
        });
    }
        
});
/*
* das auto kann nicht eingesetzt werden, weil das betreffende Element von der position 'bottom' aus gemessen werden muss.*/
