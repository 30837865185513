import 'slick-carousel';
import $ from 'jquery';
import getFormValuesByParent from "../helper_functions/getFormValuesByParent";
import initSelectize from "../helper_functions/initSelectize";
import reFetchProduktFinderSelectizeOptions from "../helper_functions/reFetchProduktFinderSelectizeOptions";

const lang = window.pimcore_locale;
const ajaxUrl = `/boeckmann/api/${lang}/product-finder/lightFilter`

export default function (element) {
    if (typeof element === "undefined") {
        console.error('missing element, provide the corresponding .product-finder-light-wrap element')
    }

    let initialSlide = element.data('start-slide');
    element.find('.productfinder-light').slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        initialSlide: initialSlide,
        prevArrow: element.find('.prev'),
        nextArrow: element.find('.next')
    });

    if (typeof parent !== "undefined") {
        initSelectize(element)
    }

    element.find('.radioselect input.is-active').prev().addClass('highlight');
    element.on('init', function(){
        $(this).find('.radioselect h4').removeClass('highlight');
        $(this).find('.radioselect input.is-active').prev().addClass('highlight');
    });

    if (initialSlide > 0) {
        fetchFormHTML(element, true);
    }

    element.on('change', "input[type=radio][name='fahrzeugart']", function() {
        fetchFormHTML(element, true);
    });
    element.on('change', "select", function() {
        fetchFormHTML(element);
    });

    refreshSelectizeOptionsOnFocus(element);
}

function fetchFormHTML(wrap, reset) {
    const data = getFormValuesByParent(wrap);
    const form = wrap.find('form');
    var currentSlide = wrap.find('.productfinder-light').slick('slickCurrentSlide');

    form.addClass('loading');

    if (reset === true) {
        delete data.finder;
    }

    const request = $.ajax({
        url: ajaxUrl,
        method: "GET",
        data: data,
        dataType: "html"
    });

    request.done(function( msg ) {
        let posFlag = $(window).scrollTop();
        wrap.html($(msg))
        initSelectize(wrap)
        form.removeClass('loading');
        wrap.find('.productfinder-light').slick({
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 1,
            prevArrow: wrap.find('.prev'),
            nextArrow: wrap.find('.next'),
            initialSlide: currentSlide,
        });
        refreshSelectizeOptionsOnFocus(wrap);
        $('html,body').scrollTop(posFlag);
    });

    request.fail(function( jqXHR, textStatus ) {
        form.removeClass('loading');
        console.error( "Request failed: " + textStatus );
    });
}


function refreshSelectizeOptionsOnFocus(parent) {
    const data = getFormValuesByParent(parent);

    parent.find('select.selectize-origin-select').each(function (i, element) {
        reFetchProduktFinderSelectizeOptions(element, data)
    });
}