import $ from 'jquery';

$(document).ready(function() {

    let searchForm = $('.sitesearch_autocomplete');
    let form = $('[name="site_search_form"]');

    if(searchForm.length > 0) {

        searchForm.each(function() {

            $(this).keyup(function() {
                const val = $(this).val();
                if(val.length >= 3) {
                    $.ajax({
                        url: "/" + form.attr('language') + "/api/?q=" + val,
                        context: $(this)
                    }).done(function (data) {
                        $(this).next().removeClass("done");
                        $('#searchbox-autocomplete-container #sitesearch-products').html(jQuery(data).find('.sitesearch-autocomplete-list--product'));
                        $('#searchbox-autocomplete-container #sitesearch-content').html(jQuery(data).find('.sitesearch-autocomplete-list--content'));
                        $('#searchbox-autocomplete-container #sitesearch-news').html(jQuery(data).find('.sitesearch-autocomplete-list--news'));
                        $('#searchbox-autocomplete-container #sitesearch-ersatzteile').html(jQuery(data).find('.sitesearch-autocomplete-list--ersatzteile'));

                        if(jQuery(data).find('.sitesearch-autocomplete-list--product').children().length > 0 ||
                            jQuery(data).find('.sitesearch-autocomplete-list--content').children().length > 0 ||
                            jQuery(data).find('.sitesearch-autocomplete-list--news').children().length > 0 ||
                            jQuery(data).find('.sitesearch-autocomplete-list--ersatzteile').children().length > 0) {
                            $('#searchbox-autocomplete-container').show();
                        } else {
                            $('#searchbox-autocomplete-container').hide();
                        }

                        if(jQuery(data).find('.sitesearch-autocomplete-list--product').children().length > 0) {
                            jQuery('[data-tabs-target="sitesearch-products"]').show();
                        } else jQuery('[data-tabs-target="sitesearch-products"]').hide();

                        if(jQuery(data).find('.sitesearch-autocomplete-list--content').children().length > 0) {
                            jQuery('[data-tabs-target="sitesearch-content"]').show();
                        } else jQuery('[data-tabs-target="sitesearch-content"]').hide();

                        if(jQuery(data).find('.sitesearch-autocomplete-list--news').children().length > 0) {
                            jQuery('[data-tabs-target="sitesearch-news"]').show();
                        } else jQuery('[data-tabs-target="sitesearch-news"]').hide();

                        if(jQuery(data).find('.sitesearch-autocomplete-list--ersatzteile').children().length > 0) {
                            jQuery('[data-tabs-target="sitesearch-ersatzteile"]').show();
                        } else jQuery('[data-tabs-target="sitesearch-ersatzteile"]').hide();

                        $(this).next().addClass("done");
                    });
                } else {
                    $('#searchbox-autocomplete-container').hide();
                }
            });

        });

    }

    let emptyTabs = $('.boeckmann-site-search .tabs-title[data-count="0"]');

    if (emptyTabs.length > 0) {
        emptyTabs.each(function() {
            $(this).on('click', function() {
                return false;
            })
        })
    }

    $('#sitesearch-tabs').find('.tabs-title').click(function() {
        if ($(this).data('count') > 0) {
            $('[name="type"]').val($(this).data('type'));
        }
    });


});
