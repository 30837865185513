import $ from "jquery";

$(document).ready(function() {
    $('.boeckmann-form select[name="order[sortOrder]"],.boeckmann-form select[name="order[oberkategorie]"],.boeckmann-form select[name="order[unterkategorie]"]').change(function() {
        $('.boeckmann-form[name="order"]').submit();
    });
    $('.boeckmann-form select[name="used_cars_form[sortOrder]"]').change(function() {
        $('.boeckmann-form[name="used_cars_form"]').submit();
    });

    const spare = $('.spare-parts-wrapper');

    if (spare.length > 0) {
        $('.boeckmann-form[name="order"]').append('<input type="hidden" id="distance" name="h" value="" />');
        if (readParams('h')) {
            let distanceTop = readParams('h');
            $('html,body').scrollTop(distanceTop);
        }
        $('.boeckmann-form[name="order"]').on('submit', function () {
            $('#distance').val($(window).scrollTop());
        });


    }
});

function readParams (paramName) {
    var param = new RegExp('[\?&]' + paramName + '=([^&#]*)').exec(window.location.href);
    if (param == null) {
        return null;
    }
    return decodeURI(param[1]) || 0;
}
