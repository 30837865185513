import $ from 'jquery';

const icons = {
    center: {
        url: document.getElementById("merchant-map").getAttribute('data-center-icon'),
        scaledSize: new google.maps.Size(61, 60),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(30.5, 60)
    },
    merchant: {
        url: document.getElementById("merchant-map").getAttribute('data-merchant-icon'),
        size: new google.maps.Size(24, 33),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(12, 33)
    },
    current: {
        url: document.getElementById("merchant-map").getAttribute('data-current-icon'),
        size: new google.maps.Size(24, 33),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(12, 33)
    },
    search: {
        url: document.getElementById("merchant-map").getAttribute('data-search-icon'),
        size: new google.maps.Size(24, 33),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(12, 33)
    }
};

const ensureAutocompleteSelect = function(input) {
    var _addEventListener = (input.addEventListener) ? input.addEventListener : input.attachEvent;
    function addEventListenerWrapper(type, listener) {
        if (type == "keydown") {
            var orig_listener = listener;
            listener = function(event) {
                var suggestion_selected = $(".pac-item-selected").length > 0;
                if (event.which == 13 && !suggestion_selected) {
                    var simulated_downarrow = $.Event("keydown", {keyCode: 40, which: 40});
                    orig_listener.apply(input, [simulated_downarrow]);
                }
                orig_listener.apply(input, [event]);
            };
        }
        _addEventListener.apply(input, [type, listener]);
    }
    if (input.addEventListener) {
        input.addEventListener = addEventListenerWrapper;
    } else if (input.attachEvent) {
        input.attachEvent = addEventListenerWrapper;
    }

    function selectFirstAddress (input) {
        google.maps.event.trigger(input, 'keydown', {keyCode:40});
        google.maps.event.trigger(input, 'keydown', {keyCode:13});
    }

    $(input).focusout(function(event) {
        selectFirstAddress(this);
    });

}

let autocomplete;
let autocompleteElement;
let placesService;
let geocoder;
let map;
let data;
let mapDom;
let currentPositionToggleButtton;
let markers = [];
let bounds;
let searchMarker;

$(document).ready(function () {

    autocompleteElement = document.getElementById("autocomplete");
    mapDom = document.getElementById("merchant-map");
    currentPositionToggleButtton = $("#merchantMapStartLocator");
    map = new google.maps.Map(document.getElementById("merchant-map"));
    placesService = new google.maps.places.PlacesService(map);
    geocoder = geocoder = new google.maps.Geocoder();
    data = JSON.parse(document.getElementById("merchant-map").getAttribute('data-markers'));
    bounds = new google.maps.LatLngBounds();

    for (var i = 0; i < data.length; i++) {
        let marker = new google.maps.Marker({
            position: new google.maps.LatLng(data[i].lat, data[i].lng),
            icon: icons[data[i].type],
            map: map,
        });
        bounds.extend(marker.getPosition());
        let infowindow = new google.maps.InfoWindow({
            content: data[i].content
        });
        marker.addListener('click', function () {
            infowindow.open(map, marker);
        });

        markers.push(markers);
    }

    map.setCenter(bounds.getCenter());
    map.fitBounds(bounds);

    google.maps.event.addListenerOnce(map, "zoom_changed", rescaleZoom);
    google.maps.event.addListenerOnce(map, "center_changed", rescaleZoom);

    autocomplete = new google.maps.places.Autocomplete(
        autocompleteElement,
        {
            componentRestrictions: {
                country: $('#countrySelect').val()
            }
        }
    );

    if (autocompleteElement.getAttribute('value') !== '') {
        placesService.textSearch({
            query: autocompleteElement.getAttribute('value')
        }, function(results, status) {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                if (results.length > 0) {
                    onPlaceChanged(results[0])
                }
            }
        });
    }
    autocomplete.addListener("place_changed", () => onPlaceChanged(autocomplete.getPlace()));

    // prevent submitting form by using google autocomplete
    document.getElementById("autocomplete").addEventListener("keydown", function(event) {
        if((event.keyCode === 13) && $('.pac-container:visible').length) {
            event.preventDefault();
            return false;
        }
    });

    ensureAutocompleteSelect(document.getElementById("autocomplete"));

    // use of jquery because selectize avoid use of original js event
    // https://github.com/selectize/selectize.js/issues/102
    $('#countrySelect').on('change', onCountryChanged)


    if (navigator.permissions) {
        navigator.permissions.query({
            name: 'geolocation'
        }).then(permission => {
            if (permission.state === "denied") {
                currentPositionToggleButtton.addClass('geolocation_permission_denied')
                return;
            }

            if (permission.state !== "granted") {
                return;
            }

            navigator.geolocation.getCurrentPosition(position => {
                setMarkerForUserPosition({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                })
            })
        })
    }

    document.getElementById('merchantMapStartLocator').addEventListener("click", () => {
        // Try HTML5 geolocation.
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };

                    document.getElementById('merchant-map-gmap-autocomplete-lat').value = pos.lat;
                    document.getElementById('merchant-map-gmap-autocomplete-lng').value = pos.lng;
                    document.getElementById('autocomplete').value = mapDom.getAttribute('data-ownlocationtext');

                    setMarkerForUserPosition(pos)

                    map.setCenter(pos);
                    currentPositionToggleButtton.prev('[type="submit"]').click();
                },
                (e) => {
                    handleLocationError(e,true, map.getCenter());
                }
            );
        }
    });
});

function createMarker(pos, icon, title) {
    return new google.maps.Marker({
        title: title,
        position: new google.maps.LatLng( pos.lat, pos.lng),
        icon: icon,
        map: map
    });
}
function setMarkerForUserPosition(pos) {
    return createMarker(pos, icons.current, mapDom.getAttribute('data-ownlocationtext'));
}

function handleLocationError(e, browserHasGeolocation, pos) {
    currentPositionToggleButtton.addClass('geolocation_permission_denied')
}

function rescaleZoom() {
    if(map.getZoom() >= 15){
        map.setZoom(13);
    }
}

function onCountryChanged() {
    autocomplete.value = null;
    document.getElementById('merchant-map-gmap-autocomplete-lat').value = '';
    document.getElementById('merchant-map-gmap-autocomplete-lng').value = '';
    document.getElementById('autocomplete').value = '';
    autocomplete.setComponentRestrictions({
        country: this.value.toLowerCase()
    })
}

function onPlaceChanged(place) {
    if (!place.geometry) {
        return;
    }
    if (typeof(searchMarker) !== "undefined") {
        searchMarker.setMap(null);
    }

    searchMarker = createMarker({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }, icons.search, mapDom.getAttribute('data-searchlocationtext'))
    map.fitBounds(bounds)
    map.panTo(place.geometry.location);
    document.getElementById('merchant-map-gmap-autocomplete-lat').value = place.geometry.location.lat();
    document.getElementById('merchant-map-gmap-autocomplete-lng').value = place.geometry.location.lng();
}


