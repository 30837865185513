import $ from "jquery";


$( document ).ready(function() {

    if($('.offer-selector').length > 0) {
        let selects = $('.offer-selector');
        let target;
        selects.each(function() {
            let option = $(this).find('.select-menu li');
            let submit = $(this).find('.selectbox-submitter');
            option.each(function() {
                $(this).on('click', function() {
                    if(!$(this).parent().hasClass('open')) {
                        $(this).parent().addClass('open');
                    } else {
                        $(this).parent().find('li').removeClass('active');
                        $(this).addClass('active').parent().removeClass('open');
                    }
                })
            });
            submit.on('click', function() {
                target = $(this).parents('.offer-selector').find('li.active').data('href');
                window.location = target;
            })
        })
    }

});
