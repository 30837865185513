import $ from 'jquery';

$( document ).ready(function() {

    if ($('.downloads-list').length > 0) {

        var vehicleFilter = $('.downloads-filter .filter');
        var vehicle;
        var selectedItems;
        vehicleFilter.each(function() {
            $(this).on('click', function() {
                vehicle = $(this).attr('id');
                vehicleFilter.removeClass('active');
                $(this).addClass('active');
                switch(vehicle) {
                    case 'ALL':
                        $('.downloads-list .cat-item').show();
                        $('.downloads-list .no-catalogues').hide();
                        break;
                    default:
                        selectedItems = $('.downloads-list .cat-item[data-fahrzeugart=' + vehicle + ']');
                        $('.downloads-list .cat-item').hide();
                        if (selectedItems.length > 0) {
                            selectedItems.show();
                            $('.downloads-list .no-catalogues').hide();
                        } else {
                            $('.downloads-list .no-catalogues').show();
                        }
                        $('#download_form_typeView').val(vehicle);
                        break;
                }
            });
        });
        if ($('#download_form_typeView').length > 0) {
            var typeView = $('#download_form_typeView').val();
            if (typeView.length < 2) {
                $('#ALL').trigger('click');
            } else {
                $('#' + typeView).trigger('click');
            }
        }

        var items = $('.downloads-list .cat-item input');
        var box = $('.order-list');
        var order;
        var lang;
        var orderId;
        var checkedItems = $('.downloads-list .cat-item input:checked');
        showHideCatalogues(checkedItems);
        items.each(function() {
            $(this).on('click', function() {
                box.find('li').remove();
                checkedItems = $('.downloads-list .cat-item input:checked');
                showHideCatalogues(checkedItems);
            });
        });
    }

    function showHideCatalogues(checkedItems) {
        if(checkedItems.length > 0) {
            box.addClass('results');
            checkedItems.each(function() {
                order = $(this).parents('.cat-item').find('h3').text();
                lang = $(this).parents('.cat-item').find('p').text();
                orderId = $(this).parents('.cat-item').attr('id');
                $('.order-list ul').append('<li data-id="' + orderId + '">' + order + ' ' + lang + '</li>');
            });
            $('.order-list li').on('click', function() {
                orderId = $(this).data('id');
                $('#' + orderId).find('input').trigger('click');
            })
        } else {
            box.removeClass('results');
        }
        if($('#count').length > 0) {
            $('#count').text(checkedItems.length);
        }
    }

});
